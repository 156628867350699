import isNil from 'lodash.isnil'

const convertCurrencyToFloat = (currency) => {
  if (!isNaN(currency) || isNil(currency)) {
    return currency
  }
  return parseFloat(
    currency
      .replace('€', '')
      .replace(/\./g, '')
      .replace(',', '.')
  )
}

const convertFloatToCurrency = (number) => {
  const isPositive = number > 0
  const currency = new Intl.NumberFormat('pt-PT', {
    style: 'currency',
    currency: 'EUR'
  })
    .format(Math.abs(number))
    .replace('€', '')
    .split(' ')
    .join('.')

  if (isPositive) {
    return `€${currency.substring(0, currency.length - 1)}`
  }

  return `-€${currency.substring(0, currency.length - 1)}`
}

export default {
  filters: {
    convertCurrencyToFloat,
    convertFloatToCurrency
  },
  methods: {
    convertCurrencyToFloat,
    convertFloatToCurrency
  }
}
