import { VueMaskDirective } from 'v-mask'
import { VMoney } from 'v-money'
import isEmpty from 'lodash/isEmpty'
import Currency from '~/utils/mixins/currency.js'

export default {
  name: 'input-block',
  mixins: [Currency],
  data() {
    return {
      internalValue: this.value !== null ? this.value : ''
    }
  },
  directives: {
    mask: VueMaskDirective,
    money: VMoney
  },
  props: {
    name: {
      type: String,
      required: true,
      validate: (val) =>
        ['name', 'nif', 'email', 'telephone', 'licensePlate'].includes(val)
    },
    isSmall: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      required: false
    },
    'is-loading': {
      type: Boolean,
      required: false,
      default: false
    },
    'has-errors': {
      type: Boolean,
      required: false,
      default: false
    },
    mask: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: [Number, String],
      required: false,
      default: null
    },
    required: {
      type: Boolean,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    uppercased: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text',
      validate: (val) =>
        [
          'text',
          'number',
          'date',
          'url',
          'email',
          'password',
          'search',
          'money',
          'percentage'
        ].includes(val)
    },
    maxValue: {
      type: Number,
      required: false
    }
  },
  computed: {
    classes() {
      return {
        'input-block--small': this.isSmall
      }
    },

    isMasked() {
      return this.mask.length !== 0
    },

    isMoney() {
      return this.type === 'money'
    },

    isPercentage() {
      return this.type === 'percentage'
    },

    haveInfoSlot() {
      return !!this.$slots.info
    },

    moneyFormat() {
      return {
        decimal: ',',
        thousands: '.',
        prefix: '€',
        precision: 2,
        masked: false
      }
    },

    percentageFormat() {
      return {
        decimal: ',',
        suffix: '%',
        precision: 2,
        masked: false
      }
    },
    infoPosition() {
      if (isEmpty(this.value)) {
        return 0
      }

      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = this.inputTextSize
      const metrics = context.measureText(this.value)
      return metrics.width
    }
  },
  methods: {
    uppercaseString(string) {
      return string.toUpperCase()
    },
    emitValue() {
      let value = this.internalValue

      if (this.uppercased) {
        value = this.uppercaseString(value)
      }

      if (this.type === 'number' && value !== '') {
        value = parseFloat(value.replace(/,/g, '.'))
      }

      if (this.type === 'money' && value !== '') {
        value = this.convertCurrencyToFloat(value)
      }

      if (this.type === 'percentage' && value !== '') {
        value = parseFloat(value.replace(/,/g, '.').replace('%', ''))
      }

      this.$emit('input', value)
    }
  }
}
